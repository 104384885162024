@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap);
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.Toastify__progress-bar {
	background: #b2a77d;
}

* {
	font-family: Open Sans, sans-serif !important;
	font-size: 14px;
}

.MuiDrawer-paperAnchorLeft {
	background-color: #535353 !important;
}

.custom-button-brown {
	position: relative;
	background-color: #b2a77d;
	color: #fff;
	text-align: center;
	border-radius: 0;
	padding: 12px 60px;
	text-transform: unset;
	font-size: 16px;
	border: 0;
	margin: auto;
	display: inline-block;
}
.custom-button-brown.dark {
	background-color: #535353;
}
.custom-button-brown:hover {
	background-color: #b2a77d;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	cursor: pointer;
}
.custom-button-brown.dark:hover {
	background-color: #535353;
}
.custom-button-brown.dark:hover:disabled {
	background-color: rgba(0, 0, 0, 0.5);
}
.custom-button-brown::after {
	content: "";
	display: block;
	border: 1px solid #ccc;
	position: absolute;
	top: 50%;
	left: 50%;
	width: calc(100% - 15px);
	height: calc(100% - 15px);
	transform: translate(-50%, -50%);
}
.custom-button-brown.dark::after {
	border: 1px solid rgba(229, 229, 229, 0.5);
}
.custom-button-brown:disabled {
	background-color: rgba(0, 0, 0, 0.5);
	cursor: unset;
}
.custom-button-brown:disabled:hover {
	box-shadow: unset;
}

.loading-screen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 99;
	color: #fff;
	text-align: center;
}

/* order history */
.order-history {
	color: #535353;
}

.ql-editor{
	min-height: 250px;
}

